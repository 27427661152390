
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { CodeModule } from '@/modules/code/store'
import { CommentModule } from '@/modules/comment/store'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import AccuracyMenu from '../menu/index.vue'
import ScoreIndicator from '../score-indicator.vue'

@Component({
  components: {
    AccuracyMenu,
    ScoreIndicator
  },
  name: 'AccuracyAppbar'
})
export default class extends Vue {
  commentModule = getModule(CommentModule, this.$store)
  codeModule = getModule(CodeModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  open = false

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get diagramModelObjects () {
    if (this.$routeName && ['editor-diagram', 'version-diagram', 'share-diagram'].includes(this.$routeName)) {
      return Object.values(this.currentDiagram?.objects || {}).map(o => this.modelModule.objects[o.modelId]).filter(o => o)
    } else {
      return Object.values(this.modelModule.objects)
    }
  }

  get diagramComments () {
    if (this.$routeName && ['editor-diagram', 'version-diagram', 'share-diagram'].includes(this.$routeName)) {
      return Object.values(this.commentModule.activeComments).filter(o => this.currentDiagram && !!o.diagrams[this.currentDiagram.id])
    } else {
      return Object.values(this.commentModule.activeComments)
    }
  }

  get invalidObjectLinkCount () {
    return this.diagramModelObjects.reduce((p, c) => p + Object.values(c.links).filter(l => 'status' in l && l.status === 'invalid').length, 0)
  }

  get inaccurateCommentCount () {
    return this.diagramComments.filter(o => o.body.type === 'inaccurate' && o.body.status === 'open').length
  }

  get organizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentPermission () {
    if (this.currentShareLink) {
      return 'read'
    } else {
      return this.organizationPermission
    }
  }

  get codeReposExistStatus () {
    return this.codeModule.codeReposExistStatus
  }

  get codeReposNotFound () {
    return this.codeModule.codeReposExistStatus.successInfo.status === 404
  }

  get showAccuracy () {
    if (this.currentShareLink) {
      return Object
        .values(this.modelModule.objects)
        .some(o => Object.values(o.links).some(l => 'status' in l && l.status === 'invalid')) || Object.values(this.commentModule.activeComments).some(o => o.body.type === 'inaccurate' && o.body.status === 'open')
    } else {
      return true
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationId (currentOrganizationId?: string) {
    if (currentOrganizationId && !this.currentShareLink && this.codeReposExistStatus.loadingInfo.organizationId !== currentOrganizationId && this.codeReposExistStatus.successInfo.organizationId !== currentOrganizationId) {
      this.codeModule.codeReposExist(currentOrganizationId)
    }
  }

  mounted () {
    if (this.currentOrganizationId && !this.currentShareLink && this.codeReposExistStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.codeReposExistStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.codeModule.codeReposExist(this.currentOrganizationId)
    }
  }
}
