
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '../store'

@Component({
  name: 'Alert'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)

  alertTick?: number

  get currentAlert () {
    return this.alertModule.currentAlert
  }

  get lastAlert () {
    return this.alertModule.lastAlert
  }

  dismissCurrentAlert () {
    this.alertModule.setCurrentAlert(null)
  }

  mounted () {
    this.alertTick = window.setInterval(() => {
      this.$nextTick(() => {
        this.alertModule.resolveCurrentAlert()
      })
    }, 500)
  }

  destroyed () {
    clearInterval(this.alertTick)
  }
}
