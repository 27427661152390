import * as farCircle from '@fortawesome/free-regular-svg-icons/faCircle'
import * as fasCheckCircle from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import * as fasLink from '@fortawesome/free-solid-svg-icons/faLink'
import * as fasThumbsDown from '@fortawesome/free-solid-svg-icons/faThumbsDown'

export default [
  farCircle.definition,
  fasCheckCircle.definition,
  fasLink.definition,
  fasThumbsDown.definition
] as const
