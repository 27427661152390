
import { CodeRepoLink, Comment, ModelObject, ModelObjectLink, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import { AlertModule } from '@/modules/alert/store'
import LinksEmpty from '@/modules/code/components/links-empty.vue'
import CodeLinksList from '@/modules/code/components/links-list.vue'
import CodeLinksSetup from '@/modules/code/components/links-setup.vue'
import { CodeModule } from '@/modules/code/store'
import CommentsEmpty from '@/modules/comment/components/empty.vue'
import CommentsList from '@/modules/comment/components/list.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import ShareHelp from '../share-help.vue'

@Component({
  components: {
    CodeLinksList,
    CodeLinksSetup,
    CommentsEmpty,
    CommentsList,
    LinksEmpty,
    Menu,
    ShareHelp,
    Tabs
  },
  name: 'AccuracyMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  codeModule = getModule(CodeModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly activator?: (() => HTMLElement | { $el: HTMLElement } | undefined)
  @Prop() readonly permission!: PermissionType
  @Prop({ default: [] }) readonly objects!: ModelObject[]
  @Prop({ default: [] }) readonly comments!: Comment[]

  defaultTab = 'reality'

  get accuracyMenu () {
    return this.$queryValue('accuracy_menu')
  }

  get accuracyHelpDialog () {
    return this.$queryValue('accuracy_help_dialog')
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId || o.id === this.currentVersion?.landscapeId)
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentShareLink?.versionId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get codeReposExist () {
    return this.codeModule.codeReposExistStatus.successInfo.status === 200
  }

  get inaccurateComments () {
    return Object.values(this.comments).filter(o => o.body.type === 'inaccurate' && o.body.status === 'open')
  }

  get invalidObjectLinks () {
    return Object
      .values(this.objects)
      .reduce<ModelObjectLink[]>((a, b) => [...a, ...Object.values(b.links)], [])
      .filter((l): l is CodeRepoLink => l.type !== 'url' && l.status === 'invalid')
  }

  get accuracyMenuTab () {
    if (this.accuracyMenu === 'comments') {
      if (this.inaccurateComments.length) {
        return 'list'
      } else {
        return 'empty'
      }
    } else if (this.accuracyMenu === 'reality') {
      if (this.codeReposExist) {
        return 'list'
      } else {
        return 'setup'
      }
    }
  }

  get tabs (): ITab[] {
    return [
      {
        caption: this.inaccurateComments.length,
        id: 'comments',
        text: 'Comments',
        to: { query: this.$setQuery({ accuracy_menu: 'comments' }) }
      },
      {
        caption: this.invalidObjectLinks.length,
        id: 'reality',
        text: 'Reality',
        to: { query: this.$setQuery({ accuracy_menu: 'reality' }) }
      }
    ]
  }

  @Watch('accuracyMenuTab')
  onAccuracyMenuTabChanged () {
    this.tabEvent()
  }

  @Watch('accuracyMenu')
  onAccuracyMenuChanged (id: string | null) {
    if (id) {
      this.saveItem(id)
    }
  }

  created () {
    const lastTab = localStorage.getItem('accuracyMenuTab')
    if (lastTab && this.tabs.some(o => o.id === lastTab)) {
      this.defaultTab = lastTab
    }
  }

  mounted () {
    this.tabEvent()
  }

  saveItem (id: string) {
    this.defaultTab = id
    localStorage.setItem('accuracyMenuTab', id)
  }

  opened () {
    if (this.currentLandscape && this.currentOrganization) {
      analytics.accuracyMenu.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  tabEvent () {
    if (this.currentLandscape && this.currentOrganization) {
      if (this.accuracyMenu === 'comments') {
        if (this.accuracyMenuTab === 'list') {
          analytics.accuracyCommentsListScreen.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        } else if (this.accuracyMenuTab === 'empty') {
          analytics.accuracyCommentsEmptyScreen.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        }
      } else if (this.accuracyMenu === 'reality') {
        if (this.accuracyMenuTab === 'list') {
          analytics.accuracyLinksListScreen.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        } else if (this.accuracyMenuTab === 'setup') {
          analytics.accuracyLinksSetupScreen.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        }
      }
    }
  }
}
