import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const thermometerEmpty: IconDefinition = {
  icon: [320, 512, [], 'f2cb', ['M272 278.5V112a112 112 0 0 0-224 0v166.5C28.3 303.2 16 334 16 368a144 144 0 0 0 288 0c0-34-12.3-64.9-32-89.5zM160 448a79.87 79.87 0 0 1-48-143.8V112a48 48 0 0 1 96 0v192.2A79.87 79.87 0 0 1 160 448z', 'M208 368a48 48 0 1 1-48-48 48 48 0 0 1 48 48z']],
  iconName: 'thermometer-empty' as any,
  prefix
}

const thermometerFull: IconDefinition = {
  icon: [320, 512, [], 'f2c7', ['M272 278.5V112a112 112 0 0 0-224 0v166.5C28.3 303.2 16 334 16 368a144 144 0 0 0 288 0c0-34-12.3-64.9-32-89.5zM160 448a79.87 79.87 0 0 1-48-143.8V112a48 48 0 0 1 96 0v192.2A79.87 79.87 0 0 1 160 448z', 'M208 368a48 48 0 1 1-64-45.1V112a16 16 0 0 1 32 0v210.9a47.87 47.87 0 0 1 32 45.1z']],
  iconName: 'thermometer-full' as any,
  prefix
}

const thermometerHalf: IconDefinition = {
  icon: [320, 512, [], 'f2c9', ['M272 278.5V112a112 112 0 0 0-224 0v166.5C28.3 303.2 16 334 16 368a144 144 0 0 0 288 0c0-34-12.3-64.9-32-89.5zM160 448a79.87 79.87 0 0 1-48-143.8V112a48 48 0 0 1 96 0v192.2A79.87 79.87 0 0 1 160 448z', 'M208 368a48 48 0 1 1-64-45.1V208a16 16 0 0 1 32 0v114.9a47.87 47.87 0 0 1 32 45.1z']],
  iconName: 'thermometer-half' as any,
  prefix
}

const thermometerQuarter: IconDefinition = {
  icon: [320, 512, [], 'f2ca', ['M272 278.5V112a112 112 0 0 0-224 0v166.5C28.3 303.2 16 334 16 368a144 144 0 0 0 288 0c0-34-12.3-64.9-32-89.5zM160 448a79.87 79.87 0 0 1-48-143.8V112a48 48 0 0 1 96 0v192.2A79.87 79.87 0 0 1 160 448z', 'M208 368a48 48 0 1 1-64-45.1V272a16 16 0 0 1 32 0v50.9a47.87 47.87 0 0 1 32 45.1z']],
  iconName: 'thermometer-quarter' as any,
  prefix
}

const thermometerThreeQuarters: IconDefinition = {
  icon: [320, 512, [], 'f2c8', ['M272 278.5V112a112 112 0 0 0-224 0v166.5C28.3 303.2 16 334 16 368a144 144 0 0 0 288 0c0-34-12.3-64.9-32-89.5zM160 448a79.87 79.87 0 0 1-48-143.8V112a48 48 0 0 1 96 0v192.2A79.87 79.87 0 0 1 160 448z', 'M208 368a48 48 0 1 1-64-45.1V144a16 16 0 0 1 32 0v178.9a47.87 47.87 0 0 1 32 45.1z']],
  iconName: 'thermometer-three-quarters' as any,
  prefix
}

export default [
  thermometerEmpty,
  thermometerFull,
  thermometerHalf,
  thermometerQuarter,
  thermometerThreeQuarters
]
