import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Screens
 */

export const accuracyCommentsListScreen = new analytics.Event({
  displayName: 'Accuracy comments list screen',
  name: 'accuracy_comments_list_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const accuracyCommentsEmptyScreen = new analytics.Event({
  displayName: 'Accuracy comments empty screen',
  name: 'accuracy_comments_empty_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const accuracyLinksListScreen = new analytics.Event({
  displayName: 'Accuracy links list screen',
  name: 'accuracy_links_list_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const accuracyLinksSetupScreen = new analytics.Event({
  displayName: 'Accuracy links setup screen',
  name: 'accuracy_links_setup_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const accuracyHelpDialog = new analytics.Event({
  displayName: 'Accuracy help dialog',
  name: 'accuracy_help_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})

/**
 * Menus
 */

export const accuracyMenu = new analytics.Event({
  displayName: 'Accuracy menu',
  name: 'accuracy_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
