
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'AccuracyScoreIndicator'
})
export default class extends Vue {
  @Prop() readonly inaccurateCommentCount!: number
  @Prop() readonly invalidObjectLinkCount!: number
  @Prop({ default: false, type: Boolean }) readonly dense!: boolean

  get inaccuracyCount () {
    return this.inaccurateCommentCount + this.invalidObjectLinkCount
  }

  get color () {
    if (this.inaccuracyCount >= 9) {
      return 'error'
    } else if (this.inaccuracyCount >= 7) {
      return 'warning'
    } else if (this.inaccuracyCount >= 4) {
      return 'info'
    } else if (this.inaccuracyCount >= 1) {
      return 'accent'
    } else {
      return 'grey lighten-4'
    }
  }

  get textColor () {
    if (this.inaccuracyCount >= 9) {
      return 'error--text'
    } else if (this.inaccuracyCount >= 7) {
      return 'warning--text'
    } else if (this.inaccuracyCount >= 4) {
      return 'info--text'
    } else if (this.inaccuracyCount >= 1) {
      return 'accent--text'
    } else {
      return 'grey--text text--lighten-4'
    }
  }

  get percentage () {
    return 100 - (this.inaccuracyCount * 10)
  }

  get full () {
    return this.inaccuracyCount <= 0
  }

  get icon () {
    if (this.inaccuracyCount >= 9) {
      return '$custom-thermometer-full'
    } else if (this.inaccuracyCount >= 7) {
      return '$custom-thermometer-three-quarters'
    } else if (this.inaccuracyCount >= 4) {
      return '$custom-thermometer-half'
    } else if (this.inaccuracyCount >= 1) {
      return '$custom-thermometer-quarter'
    } else {
      return '$custom-thermometer-empty'
    }
  }
}
