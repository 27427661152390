import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface IAlert {
  color?: string
  duration: number
  message: string
}

export interface IAlertModule {
  lastAlert: IAlert | null
  currentAlert: IAlert | null
  currentAlertTime: number | null
  queuedAlerts: IAlert[]
}

const name = 'alert'

@Module({
  name,
  namespaced: true
})
export class AlertModule extends VuexModule {
  static namespace = name

  lastAlert: IAlert | null = null
  currentAlert: IAlert | null = null
  currentAlertTime: number | null = null

  queuedAlerts: IAlert[] = []

  @Mutation
  resetQueuedAlerts () {
    this.queuedAlerts = []
  }

  @Mutation
  setCurrentAlert (alert: IAlert | null) {
    if (alert) {
      this.currentAlert = alert
      this.currentAlertTime = Date.now()
    } else {
      this.currentAlert = null
      this.currentAlertTime = null
    }
  }

  @Mutation
  setLastAlert (alert: IAlert) {
    this.lastAlert = alert
  }

  @Mutation
  pushAlert (alert: Partial<IAlert>) {
    this.queuedAlerts.push({
      duration: 5000,
      message: 'Alert!',
      ...alert
    })
  }

  @Action({ rawError: true })
  resolveCurrentAlert () {
    if (this.queuedAlerts.length) {
      if (!this.currentAlert) {
        this.setCurrentAlert(this.queuedAlerts.splice(0, 1)[0])
      } else if (this.currentAlert && this.currentAlert.duration >= 0 && this.currentAlertTime && Date.now() > this.currentAlertTime + this.currentAlert.duration) {
        this.setLastAlert(this.currentAlert)
        this.setCurrentAlert(this.queuedAlerts.splice(0, 1)[0])
      }
    } else {
      if (this.currentAlert && this.currentAlert.duration >= 0 && this.currentAlertTime && Date.now() > this.currentAlertTime + this.currentAlert.duration) {
        this.setCurrentAlert(null)
      }
    }
  }
}
